<template>
  <div class="row justify-content-center">
    <div
        class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center"
    >
      <!-- Heading -->
      <div class="text-center">
        <img
            src="../../assets/img/logo/qevo_blue.svg"
            class="img-fluid"
            alt="..."
            width="240"
        />
      </div>

      <br>
      <h1 class="display-4 text-center mb-3">Confirme seu E-mail</h1>
      <!-- Subheading -->
      <p class="text-muted text-center mb-5">
        Acabamos de enviar um link de confirmação para o seu endereço de e-mail abaixo:
      </p>

      <p class="text-muted text-center mb-5">
        {{ signupingEmail }}
      </p>

      <p class="text-muted text-center mb-5">
        Verifique sua caixa de e-mail para confirmar a criação de sua conta.
      </p>

      <p class="text-muted text-center mb-5">
        Não recebeu o e-mail? <a href="#" @click="resendConfirmationEmail">Enviar novamente.</a>
      </p>
      <p v-if="emailSent" class="text-muted text-center mb-5">
        Email reenviado
      </p>
    </div>
    <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
      <!-- Image -->
      <div
          class="bg-cover h-100 min-vh-100 mt-n1 me-n3"
          :style="{
          'background-image':
            'url(' +
            require('../../assets/img/covers/unauthenticated_screen_3.png') +
            ')',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailConfirmFeedback",
  data() {
    return {
      emailSent: false,
    }
  },
  computed:{
    signupingEmail(){
      return this.$store.getters['auth/getSignupingEmail'];
    }
  },
  methods: {
    resendConfirmationEmail() {
      this.$store.dispatch('auth/resendConfirmationEmail', {
        email: this.signupingEmail
      }).then(() => {
        this.emailSent = true
      });
    }
  }
}
</script>

<style scoped>

</style>
